import React, { useEffect, useState } from 'react';
import { Divider, Button } from 'antd';
import CVScore from '../CVScore';
import Comment from './Comment';
import AddComment from './AddComment';
import { useDispatch, useSelector } from 'react-redux';
import { selectCandidateProfile, selectMentions, setMentions } from '../../../slice/candidateSlice';
import { EmptyList } from '../../../../../shared/components/List/EmptyList';
import { IMentionMember, permissionsActions } from '../../../../../shared/utils/interfaces/AuthInterface';
import { useGetCommentsQuery, useGetMembersQuery } from '../../../slice/candiddateApiSlice';
import LazyLoader from '../../../../../shared/components/LazyLoader/load';
import { IGetComments } from '../../../../../shared/utils/interfaces/commentsFaces';
import { useNavigate, useParams } from 'react-router-dom';
import { IMemberItem } from '../../../../../shared/utils/interfaces/createJobTabs';
import { regexPatterns } from '../../../../../shared/utils/regexPatterns';
import ModalComponent from '../../../../../shared/components/Modals/ModalComponent';
import ModalBody from '../../../../../shared/components/Modals/ModalBody';
import InterviewQuestions from './InterviewQuestions';
import ChangeStageActions from '../ChangeStageActions'; 
import Attributes from '../Attributes';
import { roleError } from '../../../../../shared/utils/constantData';
import { WithTooltip } from '../../../../../shared/components/tooltip.tsx/withTootip';
import FeaturesList from './FeaturesList';
import { generateAndPrintPDF } from '../../../../../shared/utils/functions/generateAndPrintPDF';
import { getImageUrl } from '../../../../../shared/utils/functions/images';
import DeleteModal from '../../../../../shared/components/Modals/DeleteModal';
import CandidatesActions from '../CandidatesActions';
import officeIcon from '../../../../../shared/assets/images/Office building.svg'
import { usePermissions } from '../../../../../shared/utils/customHooks/usePermissions';
import ExclamationYellow from '../../../../../shared/assets/images/ExclamationYellow.svg';

const fomratCommentMentions = (text: string, mentionables: IMentionMember[]) => {
    return (
        <>
            {text
                .split(' ')
                .map((word, key) => {
                    const memberName = mentionables.find(({ id }) => Number(word.replace(/^.*\((\d+)\).*$/, '$1')) === id)?.display
                    return (
                        regexPatterns.mentionPattern.test(word) && mentionables.some(({ id }) => Number(word.replace(/^.*\((\d+)\).*$/, '$1')) === id) ?
                            <span key={key} className='text-indigo-700'>{'@' + memberName} </span>
                            : regexPatterns.mentionPattern.test(word) ?
                                word.replace(regexPatterns.mentionWithAtSymbol, '') + ' '
                                :
                                word + ' '
                    )
                })}
        </>
    )
}

const Comments: React.FC = () => {
    const dispatch = useDispatch();
    const candidate = useSelector(selectCandidateProfile);
    const navigate = useNavigate();
    const { applicationId, jobId } = useParams();
    const {
        data: commentsData,
        isSuccess: isSuccessComments,
        isLoading: isLoadingComments,
        isFetching: isFetchingComments,
        isError: isErrorComments
    } = useGetCommentsQuery(applicationId);
    const {
        data: membersData,
        isSuccess: isSuccessMembers,
    } = useGetMembersQuery(jobId);
    const mentionables = useSelector(selectMentions);
    const [viewMore, setViewMore] = useState<boolean>(true);
    const [isInterviewModalOpen, setIsInterviewModalOpen] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState(false)
    const { getInterviewQuestions } = permissionsActions
    const { hasJobPermission } = usePermissions()
    const departmentName = candidate?.department?.name
    const parentDepartment = candidate?.department?.parentDepartment?.name
    useEffect(() => {
        if (isSuccessMembers) {
            dispatch(setMentions(membersData?.users?.length !== 0 && Array.isArray(membersData?.users) ?
                membersData?.users?.map(({ id, firstName, lastName, email }: IMemberItem) => {
                    return {
                        id,
                        display: `${firstName}${lastName}`,
                        name: `${firstName} ${lastName}`,
                        email
                    }
                }) : []))
        }
    }, [isSuccessMembers, dispatch, membersData]);

    const handleTooltipClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        navigate(`/similarApplication/${candidate?.id}`);
    };
    return (
        <>
            <div className='top-0 flex flex-col w-auto overflow-auto md:sticky md:h-screen scrollbar-hide p-7'>
                <DeleteModal
                    isVisibleModal={openWarningModal}
                    handleCloseModal={() => setOpenWarningModal(false)}
                    title="Invalid Email Address"
                    content={<p>You can’t email this candidate,<br />no invalid email address available or candidate does't has email</p>}
                    footer={
                        <Button
                            className="w-full py-1 btn-default"
                            onClick={() => setOpenWarningModal(false)}
                        >
                            Ok
                        </Button>} />
                <div className='flex flex-wrap justify-between mb-1.5'>
                    <WithTooltip
                        visible={!hasJobPermission(candidate?.userRole?.roleName, getInterviewQuestions)}
                        placement='bottom'
                        title={roleError}
                    >
                        <Button className='px-3 mb-3 btn-secondary' disabled={!hasJobPermission(candidate?.userRole?.roleName, getInterviewQuestions)} onClick={() => setIsInterviewModalOpen(true)}>
                            Interview Questions
                        </Button>
                    </WithTooltip>
                    <div className='flex'>
                        <CandidatesActions withDropDown={false} candidate={candidate} />
                        <div className="mb-3 ml-2">
                            <ChangeStageActions candidateId={candidate?.id} hiringStageId={candidate?.pipelineHiringStageId} />
                        </div>
                    </div>
                </div>
                <div className='candidate-info'>
                    <div className="flex flex-nowrap">
                        <h3 className='font-bold text-gray-600 me-4'>{`${candidate?.firstName} ${candidate?.lastName}`}</h3>
                        <div className="me-3"><CVScore cvScore={candidate?.cvScore} /></div>
                        {candidate?.isSimilar && <WithTooltip
                            className='w-36'
                            placement='right'
                            colorText='#B45309'
                            backgroundColor='#FEF3C7'
                            width='w-48'

                            title={<>Similarity detection: It appears that the same candidate has applied for multiple positions.
                                <h4 className='mt-3 cursor-pointer' onClick={handleTooltipClick}>Click to compare.</h4></>}
                        >
                            <img onClick={handleTooltipClick} src={ExclamationYellow} alt='Similarity detection' className='inline' />
                        </WithTooltip>}
                    </div>
                    {departmentName && (
                        <div className='flex mb-4'>
                            <img className="mr-1" src={officeIcon} alt='department' />
                            <div className="text-sm font-normal text-gray-600">
                                {parentDepartment ? `${parentDepartment} / ` : ''}{departmentName}
                            </div>
                        </div>
                    )}
                    <p className='text-gray-600 mb-1.5'>{(viewMore ? candidate?.cvSummary : candidate?.longSummary) ?? 'Score and Summary for this candidate are being processed...'}</p>
                    {candidate?.longSummary &&
                        <Button className='p-0 btn-link' onClick={() => { setViewMore(!viewMore) }}>View {`${viewMore ? 'More' : 'Less'}`}</Button>}
                </div>
                <Divider dashed className='my-4 border-neutral-500' />
                {(candidate?.strengths?.length > 0 || candidate?.weaknesses?.length > 0) &&
                    <>
                        <div className='grid gap-3 md:grid-cols-2'>
                            {candidate?.strengths?.length > 0 && <FeaturesList title="Strength" list={candidate?.strengths} />}
                            {candidate?.weaknesses?.length > 0 && <FeaturesList title="Weakness" list={candidate?.weaknesses} />}
                        </div>
                        <Divider dashed className='my-4 border-neutral-500' />
                    </>
                }
                <Attributes applicationCriteriasEvaluation={candidate?.applicationCriteriasEvaluation} />
                <Divider dashed className='my-4 border-neutral-500' />
                <div className='pb-2 text-indigo-700'>
                    <span className="inline">Comments {`(${commentsData?.comments?.length ?? '0'})`}</span>
                    <img src={getImageUrl('commentIcon')} alt="Comments" className="inline ml-2" />
                </div>
                <div className="flex flex-col-reverse flex-grow overflow-auto min-h-[14rem] pt-3">
                    {isLoadingComments || isFetchingComments ?
                        <div className='w-full mb-auto'>
                            <LazyLoader />
                            <p className='mt-4 text-base font-normal text-indigo-600'>Retrieving comments..</p>
                        </div>
                        : (isSuccessComments && commentsData?.comments?.length > 0) ?
                            commentsData?.comments?.map(({ user: { firstName, lastName }, createdAt, text }: IGetComments, key: number) => (
                                <Comment key={key} firstName={firstName} lastName={lastName} postedAt={createdAt} text={fomratCommentMentions(text, mentionables)} />
                            ))
                            : isErrorComments ?
                                <div className="flex items-center justify-center flex-grow h-full">
                                    <EmptyList title="Something went wrong" description='Failed loading comments, please refresh' />
                                </div>
                                :
                                <div className="flex items-center justify-center flex-grow h-full">
                                    <EmptyList title="No comments" description='Be the first to comment' />
                                </div>
                    }
                </div>
                <Divider dashed className='my-4 border-neutral-500' />
                <AddComment users={mentionables} />
            </div>
            <ModalComponent visibleModal={isInterviewModalOpen}
                handleCloseModal={() => setIsInterviewModalOpen(false)}
                content={
                    <ModalBody
                        title={
                            <div className='flex mb-3.5'>
                                Interview Questions
                                <img className='mx-3 cursor-pointer' onClick={() => generateAndPrintPDF('download')} src={getImageUrl('download')} alt='download' />
                                <img className='cursor-pointer' onClick={() => generateAndPrintPDF('print')} src={getImageUrl('Printer')} alt='print' />
                            </div>}
                        className="text-start"
                    >
                        <div id="pdf-content">
                            <InterviewQuestions />
                        </div>
                    </ModalBody>
                }
            />
        </>
    );
};

export default Comments;
